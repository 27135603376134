










































































































































































































import Vue from "vue";
import Layout from "@/router/layouts/main.vue";
import { mapActions, mapGetters } from "vuex";
import i18n from "@/i18n/i18n";
import _ from "lodash";
import TopicsSearch from "@/components/topicsSearch.vue";

Object.defineProperty(Vue.prototype, "$_", { value: _ });

export default Vue.extend({
  name: "support" as string,
  data() {
    return {
      devUrl: process.env.VUE_APP_SONGOROO_WEB_STAGE_CASSOVIA,
      i18: i18n,
      query: "",
      searching: false,
    };
  },

  components: {
    Layout,
    TopicsSearch,
  },

  mounted() {
    let support = sessionStorage.getItem("Support");
    if (support != "true") {
      this.setPopularTopics();
      this.setPopularSections();
    }
  },
  computed: {
    ...mapGetters("helpAndSupportModule", {
      popularTopicsEn: "GET_POPULAR_TOPICS_EN",
      popularTopicsSk: "GET_POPULAR_TOPICS_SK",
      popularSectionsEn: "GET_POPULAR_SECTIONS_EN",
      popularSectionsSk: "GET_POPULAR_SECTIONS_SK",
      searchTopicsResults: "GET_SEARCH_TOPICS",
    }),
    locale(): string {
      return i18n.locale;
    },
  },
  methods: {
    ...mapActions("helpAndSupportModule", {
      setPopularTopics: "FETCH_POPULAR_TOPICS",
      setPopularSections: "FETCH_POPULAR_SECTIONS",
      setSearchTopics: "SEARCH_TOPICS",
    }),
    navigateToForm() {
      this.$router.push("/support/submit-request");
    },
    search: _.debounce(async function (): Promise<void> {
      if (!this.query) {
        this.searching = false;
        this.$store.commit("helpAndSupportModule/SET_SEARCH_TOPICS", "");
        return;
      }
      this.searching = true;
      await this.setSearchTopics({ query: this.query, lang: this.locale });
      if (!this.query) {
        this.$store.commit("helpAndSupportModule/SET_SEARCH_TOPICS", "");
      }
      this.searching = false;
    }, 500),
  },
  beforeDestroy() {
    this.$store.commit("helpAndSupportModule/SET_SEARCH_TOPICS", "");
  },
});
